.subscribe-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .subscribe-icon {
    background-color: #4caf4f00;
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
  }
  
  .modal-content {
    background-color: rgb(255, 255, 255); 
    padding: 12px;
    border-radius: 5px;
    max-width: 500px;
    width: 85%;
    text-align: center;
  }
  
  .subscribe-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4a6fb1;
    color: #ffffff;
    text-decoration: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .subscribe-link:hover {
    background-color: #3a5a8f;
  }

  .close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #f4433600;
    color: rgb(48, 47, 47);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }

  .qrcode-image {
    max-width: 200px;
    margin-bottom: 15px;
  }