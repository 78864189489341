.article-card {
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 60vh;
  max-height: max-content;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.article-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-header {
  display: flex;
  align-items: center;
}

.article-tag {
  background-color: #bbdefb;
  color: #0d47a1;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.7em;
  font-weight: bold;
}

.article-score {
  color: #777;
  font-size: 0.9em;
  font-weight: bold;
  margin-left: 10px;
}

.article-date {
  color: #777;
  font-size: 0.9em;
  font-weight: bold;
}

.title {
  font-size: 1.2em;
  color: #222;
  font-weight: bold;
  margin-bottom: 14px;
  line-height: 1.6;
  text-align: left;
}

.summary {
  font-size: 1em;
  font-weight: bold;
  color: #313131;
  margin-bottom: 14px;
  flex-grow: 1;
  line-height: 1.7;
  text-align: left;
}

.divider {
  border: none;
  border-top: 0.5px solid #aaa;
  margin: 10px 0;
}

.card-footer {
  margin-top: auto;
  text-align: center;
}

.card-footer a {
  color: #3d7ede;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.2s ease;
}

.more-link {
  display: block; /* 将链接设置为块级元素 */
  padding: 10px; /* 添加内边距以增大点击区域 */
}