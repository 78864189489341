.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.slider {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.card-container {
  position: absolute;
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  transform: translateZ(0);
  will-change: transform;
  transition: transform 0.3s ease-out;
  overscroll-behavior: contain;
  touch-action: pan-x;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.card-container {
  flex: 0 0 100%;
  width: 100%;
  padding: 20px 5%;
  box-sizing: border-box;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media (min-width: 768px) {
  .card-container {
    padding: 20px 10%;
  }
}

.card {
  flex: 0 0 auto;
  width: calc(100% - 2 * var(--card-margin));
  max-width: min(500px, 90vw);
  margin: 0 var(--card-margin);
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.8); /* 添加透明度 */
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  touch-action: pan-y pinch-zoom;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: auto;
}

:root {
  --card-margin: 10px;
}

@media (min-width: 768px) {
  :root {
    --card-margin: 20px;
  }
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  border-color: #d0d0d0;
}